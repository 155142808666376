import { useTranslation } from 'react-i18next';
import SimpleCard from '../../../ui/cards/SimpleCard';
import Lottie from 'lottie-react';
import '../../../styles/components/charts/pieChartComponent.scss';
// import statsLottie from '../../../assets/json/lottie_animation/statsInfographicsLottie.json';
import setupTrackingLottie from '../../../assets/json/lottie_animation/setup_tracking.json';
import '../../../styles/pages/dashboard/stats/aiCheckoutTrackingStats.scss';
import MainDeviceBasedCRChart from '../../../components/charts/ConversionRate/DeviceBased/MainDeviceBasedCRChart';
import MainOverallAndLocationBasedCRChart from '../../../components/charts/ConversionRate/OverallAndLocationBased/MainOverallAndLocationBasedCRChart';
import WeeklyConversionRateLineChart from '../../../components/charts/ConversionRate/Weekly/WeeklyConversionRateLineChart';
import MainAverageCartValueChart from '../../../components/charts/AverageCartValue/MainAverageCartValueChart';
import MainCTRChart from '../../../components/charts/ClickThroughRate/MainCTRChart';
import MainTimeAndDayCRChart from '../../../components/charts/ConversionRate/TimeAndDay/MainTimeAndDayCRChart';
import DummyStats from './dummyStats/DummyStats';
import MainUserDemographicsChart from '../../../components/charts/UserDemographics/MainUserDemographicsChart';
import OverallConversionRatePieChart from '../../../components/charts/ConversionRate/OverallAndLocationBased/OverallConversionRatePieChart';
import MainReferrersChart from '../../../components/charts/Referrers/MainReferrersChart';
import MainCTRPerTimeOfDayChart from '../../../components/charts/ClickThroughRate/CTRPerTimeOfDay/MainCTRPerTimeOfDayChart';
import MainScreenSizeBasedCRChart from '../../../components/charts/ConversionRate/ScreenSizeBased/MainScreenSizeBasedCRChart';

type Props = {
  isTrackable: boolean;
  isTimesUp: boolean;
  stats: any;
  timeFilter: string;
  customPeriodFilterDateRange: {
    start_date: string | undefined;
    end_date: string | undefined;
  };
  eventFilter: string;
};

const AiCheckoutTrackingStats = ({
  isTrackable,
  isTimesUp,
  stats,
  timeFilter,
  customPeriodFilterDateRange,
  eventFilter,
}: Props) => {
  const { t } = useTranslation();

  const renderCharts = () => {
    return (
      <div className='chartLayout'>
        <div className='chartLayout--content'>
          <div className='chartLayout--content-small'>
            <OverallConversionRatePieChart
              timeFilter={timeFilter}
              customPeriodFilterDateRange={customPeriodFilterDateRange}
              eventFilter={eventFilter}
            />
          </div>
          <div className='chartLayout--content-large'>
            <WeeklyConversionRateLineChart eventFilter={eventFilter} />
          </div>
        </div>
        {/* --------------------------------------------- */}

        <div className='chartLayout--content'>
          <div className='chartLayout--content-large'>
            <MainDeviceBasedCRChart
              timeFilter={timeFilter}
              customPeriodFilterDateRange={customPeriodFilterDateRange}
              eventFilter={eventFilter}
            />
          </div>
          <div className='chartLayout--content-small'>
            <MainOverallAndLocationBasedCRChart
              timeFilter={timeFilter}
              customPeriodFilterDateRange={customPeriodFilterDateRange}
              eventFilter={eventFilter}
            />
          </div>
        </div>

        {/* --------------------------------------------- */}

        {eventFilter === 'purchase' ? (
          <div className='chartLayout--content'>
            <div className='chartLayout--content-small'>
              <MainCTRChart
                timeFilter={timeFilter}
                customPeriodFilterDateRange={customPeriodFilterDateRange}
                eventFilter={eventFilter}
              />
            </div>
            <div className='chartLayout--content-large'>
              <MainAverageCartValueChart
                timeFilter={timeFilter}
                customPeriodFilterDateRange={customPeriodFilterDateRange}
                eventFilter={eventFilter}
              />
            </div>
          </div>
        ) : (
          <div className='chartLayout--content'>
            <div className='chartLayout--content-small'>
              <MainCTRChart
                timeFilter={timeFilter}
                customPeriodFilterDateRange={customPeriodFilterDateRange}
                eventFilter={eventFilter}
              />
            </div>
          </div>
        )}

        {/* --------------------------------------------- */}

        <div className='chartLayout--content'>
          <div className='chartLayout--content-large'>
            <MainTimeAndDayCRChart
              timeFilter={'day_of_week'}
              eventFilter={eventFilter}
            />
          </div>
          <div className='chartLayout--content-small'>
            <MainTimeAndDayCRChart
              timeFilter={'time_of_day'}
              eventFilter={eventFilter}
            />
          </div>
        </div>

        {/* --------------------------------------------- */}

        <div className='chartLayout--content'>
          <div className='chartLayout--content-large'>
            <MainReferrersChart
              timeFilter={timeFilter}
              customPeriodFilterDateRange={customPeriodFilterDateRange}
              eventFilter={eventFilter}
            />
          </div>
          <div className='chartLayout--content-small'>
            <MainCTRPerTimeOfDayChart
              timeFilter={timeFilter}
              customPeriodFilterDateRange={customPeriodFilterDateRange}
              eventFilter={eventFilter}
            />
          </div>
        </div>

        {/* --------------------------------------------- */}

        <div className='chartLayout--content'>
          <MainScreenSizeBasedCRChart
            timeFilter={timeFilter}
            customPeriodFilterDateRange={customPeriodFilterDateRange}
            eventFilter={eventFilter}
          />
        </div>

        {/* --------------------------------------------- */}

        <div className='chartLayout--content'>
          <MainUserDemographicsChart
            timeFilter={timeFilter}
            customPeriodFilterDateRange={customPeriodFilterDateRange}
            eventFilter={eventFilter}
          />
        </div>
      </div>
    );
  };

  return (
    <div className='aiCheckoutTrackingStats--container'>
      <div className='aiCheckoutTrackingStats'>
        {isTrackable && !isTimesUp && (
          /*  <SimpleCard grow={false}>
            <div className='aiCheckoutTrackingStats__card'>
              <h3>{t('TrendsText1')}</h3>
              <div className='aiCheckoutTrackingStats__card--lottie'>
                <Lottie animationData={statsLottie} loop={true} />
              </div>
            </div>
          </SimpleCard> */
          <DummyStats />
        )}
        {!isTrackable && !isTimesUp && (
          <SimpleCard grow={false}>
            <div className='aiCheckoutTrackingStats__card'>
              <h3>{t('TrendsText2')}</h3>
              <div className='aiCheckoutTrackingStats__card--lottie'>
                <Lottie animationData={setupTrackingLottie} loop={true} />
              </div>
            </div>
          </SimpleCard>
        )}
        {stats?.additional_stats?.tracker_configured_timestamp &&
          stats?.additional_stats?.is_tracker_classes_configured &&
          isTimesUp &&
          renderCharts()}
      </div>
    </div>
  );
};

export default AiCheckoutTrackingStats;
