import { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import CssClasses from './CssClasses/CssClasses';
import Integration from './Integration/Integration';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../ui/noData/NoData';
import { useTranslation } from 'react-i18next';
import getCurrentRoute from '../../../lib/helper-functions/getCurrentRoute';
import SubPageInnerContentHolder from '../../../components/subPageContentHolder/SubPageInnerContentHolder';
import get_checkout_tracking_api_call from '../../../lib/api/integration/get_checkout_tracking_api_call';
import post_integration_api_call from '../../../lib/api/integration/post_integration_api_call';
import Trends from './Trends/Trends';
import { allRoutes } from '../../../lib/constants/route';
import Autofill from './Autofill/Autofill';
import TrackerSwitch from './Integration/TrackerSwitch';
import { settingSVG } from '../../../lib/allCustomSVGs';
import { submitLogToBugsnag } from '../../../lib/api/log';
import { asyncHandler } from '../../../lib/api/async-handler';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { HELP_LINE_API } from '../../../lib/constants/central-api';
import EventTracking from './CustomEvent/EventTracking';

interface ApiDataType {
  api_key: string;
  [key: string]: any;
}

function CheckoutTracking() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('null');
  const [key, setKey] = useState<string>('');
  const [isTrackable, setIsTrackable] = useState<boolean>(false);
  const [apiData, setAPIData] = useState<ApiDataType[] | null>(null);
  const [isAutofillEnabled, setIsAutofillEnabled] = useState<boolean>(false);
  const [isTrackerON, setIsTrackerON] = useState<boolean>(true);
  const shopURL = useSelector(
    (state: RootState) => state.user?.company?.webshop_url
  );
  const shopSlug = useSelector(
    (state: RootState) => state.user?.company?.shop_slug
  );
  const accessToken = useSelector(
    (state: RootState) => state.auth.authorization.accessToken
  );
  const isWhiteLabel = useSelector(
    (state: RootState) => state.UI?.isWhiteLabel
  );

  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }
  const { t } = useTranslation();

  const location = useLocation();
  const currentRoute = getCurrentRoute(location);

  useEffect(() => {
    if (
      currentRoute === 'manage' ||
      currentRoute === 'trends' ||
      currentRoute === 'autofill'
    )
      getAvailableTrackingData();
  }, [currentRoute]);

  useEffect(() => {
    if (
      (currentRoute === 'integration' || currentRoute === 'custom-events') &&
      key === ''
    ) {
      getAvailableTrackingData();
    }
  }, [currentRoute, key]);

  const getAvailableTrackingData = async () => {
    if (currentRoute === 'trends') {
      setLoading('success');
      return;
    }
    setLoading('pending');
    try {
      const res: ApiDataType[] = await get_checkout_tracking_api_call();

      if (!res || res.length === 0) {
        const sendingData = {
          name: 'checkout-tracking-class-names',
          type: 'tracker',
          configuration: { key: 'value' },
        };
        const postRes = await post_integration_api_call(
          sendingData,
          undefined,
          undefined,
          undefined
        );
        if (postRes && postRes.api_key) {
          setKey(postRes.api_key);
        }
        setIsTrackable(false);
      } else {
        if (
          res[res.length - 1]?.configuration &&
          JSON.stringify(res[res.length - 1].configuration) ===
            JSON.stringify({ key: 'value' })
        ) {
          setIsTrackable(false);
        } else {
          setIsTrackable(true);
        }
        setAPIData(res);
        if (res?.length > 0) {
          const additionalConfigs = res[res?.length - 1]?.additional_configs;

          if (typeof additionalConfigs?.is_autofill_enabled === 'string') {
            setIsAutofillEnabled(
              additionalConfigs?.is_autofill_enabled === 'true' ? true : false
            );
          } else {
            setIsAutofillEnabled(false);
          }

          if (typeof additionalConfigs?.is_tracker_active === 'string') {
            setIsTrackerON(
              additionalConfigs?.is_tracker_active === 'false' ? false : true
            );
          } else {
            setIsTrackerON(true);
          }
        }
        setKey(res[res.length - 1]?.api_key || '');
      }
      setLoading('success');
    } catch (error) {
      console.error('Error fetching tracking data:', error);
      setLoading('error');
    }
  };

  /* ================================================== */
  const onSubmitHandler = async () => {
    if (!shopURL || !shopSlug) {
      return;
    }

    try {
      const sendingData = JSON.stringify({
        type: 'tracking',
        data: {
          cms_url: `https://${shopURL}`,
          shop_slug: shopSlug,
        },
      });

      const requestInfo: RequestInfo = {
        url: HELP_LINE_API,
        method: 'POST',
        body: sendingData,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'HELP_LINE_API_POST';
      const customMsg = t('EmailSentSuccessfully');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const response = res.data;
      if (res.actionType === actionType && response.success) {
      }
    } catch (exception) {
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }
  };
  /* ================================================== */

  const title =
    currentRoute === 'manage'
      ? t('ManageTrackingClassNames')
      : currentRoute === 'integration'
      ? t('CheckoutTrackingIntegration')
      : currentRoute === 'autofill'
      ? t('Autofill')
      : currentRoute === 'custom-events'
      ? t('CustomEventTracking')
      : currentRoute === 'trends'
      ? t('Recommendations')
      : t('CheckoutTracking');

  return (
    <SubPageInnerContentHolder
      title={title ? title : t('CheckoutTracking')}
      mainBtnIcon={
        currentRoute === 'manage' || currentRoute === 'custom-events'
          ? settingSVG
          : undefined
      }
      mainBtnTitle={
        currentRoute === 'manage' || currentRoute === 'custom-events'
          ? t('SetupForMe')
          : undefined
      }
      mainBtnAction={
        currentRoute === 'manage' || currentRoute === 'custom-events'
          ? () => {
              onSubmitHandler();
            }
          : undefined
      }
      // subTitle={subTitle}
      // backBtnLink={HOME_ROUTE}
    >
      {loading === 'pending' && <LoadingSpinner />}
      {loading === 'error' && (
        <NoData
          title={t('ErrorFetchingData')}
          subTitle={t('SomethingWentWrong-msg')}
        />
      )}

      <Routes>
        {allRoutes['/tracking/css-classes/manage'] && (
          <Route
            path='manage/*'
            element={
              loading === 'success' ? (
                <CssClasses
                  data={apiData}
                  apiKey={key}
                  setScriptKey={setKey}
                  isAutofillEnabled={isAutofillEnabled}
                  isTrackerON={isTrackerON}
                />
              ) : null
            }
          />
        )}
        {allRoutes['/tracking/css-classes/autofill'] && (
          <Route
            path='autofill/*'
            element={
              loading === 'success' ? (
                <Autofill
                  setIsAutofillEnabled={setIsAutofillEnabled}
                  isAutofillEnabled={isAutofillEnabled}
                  isTrackerON={isTrackerON}
                  data={apiData}
                  setScriptKey={setKey}
                />
              ) : null
            }
          />
        )}
        {allRoutes['/tracking/css-classes/integration'] && (
          <Route
            path='integration/*'
            element={
              key ? (
                <>
                  <TrackerSwitch
                    setIsTrackerON={setIsTrackerON}
                    isTrackerON={isTrackerON}
                    isAutofillEnabled={isAutofillEnabled}
                    data={apiData}
                    setScriptKey={setKey}
                  />
                  <Integration apiKey={key} isTrackerON={isTrackerON} />
                </>
              ) : null
            }
          />
        )}
        {allRoutes['/tracking/css-classes/trends'] && (
          <Route
            path='trends/*'
            element={
              loading === 'success' ? (
                <Trends apiKey={key} isTrackable={isTrackable} />
              ) : null
            }
          />
        )}
        {allRoutes['/tracking/css-classes/custom-events'] && (
          <Route
            path='custom-events/*'
            element={
              loading === 'success' ? (
                <EventTracking
                  apiKey={key}
                  data={apiData}
                  setScriptKey={setKey}
                  isAutofillEnabled={isAutofillEnabled}
                  isTrackerON={isTrackerON}
                />
              ) : null
            }
          />
        )}
      </Routes>
    </SubPageInnerContentHolder>
  );
}

export default CheckoutTracking;
