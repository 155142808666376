import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../ui/noData/NoData';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import colorsForCharts from '../../../lib/constants/colors';

type Props = {
  apiResponse: any;
};

const OverallReferrersBarChart = ({ apiResponse }: Props) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState<any[]>([]);
  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('pending');

  const getConversionRateData = async (res: any) => {
    setLoading('pending');
    if (res && res?.length > 0) {
      const newChartData = res?.map((item: any) => {
        return {
          key: item.source,
          value: item.count,
        };
      });

      setChartData(newChartData);
      setLoading('success');
    } else {
      setLoading('null');
    }
  };

  useEffect(() => {
    if (apiResponse) {
      getConversionRateData(apiResponse);
    }
  }, [apiResponse]);

  return (
    <>
      {loading === 'pending' && <LoadingSpinner />}

      {loading === 'null' && (
        <NoData
          title={t('No data available')}
          subTitle={t('NeedMoreInteractionsToGenerateStats')}
        />
      )}
      {loading === 'success' && (
        <>
          <div className='chart__bottom--content-chart'>
            <div
              style={{ width: '100%' }}
              className='chart__bottom--content-chart-pie'
            >
              <ResponsiveContainer width='100%' height={280}>
                <BarChart
                  data={chartData}
                  margin={{
                    top: 20,
                    right: 50,
                    left: -10,
                    bottom: 10,
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis
                    dataKey='key'
                    label={{
                      // value: 'Conversion Rate (%)',
                      position: 'insideBottom',
                      offset: -5,
                    }}
                  />
                  <YAxis
                    domain={[0, 100]}
                    label={{
                      // value: 'Conversion Rate (%)',
                      angle: -90,
                      position: 'insideLeft',
                      offset: 20,
                      margin: 10,
                    }}
                  />
                  <Bar dataKey='value' fill='#8884d8'>
                    <LabelList
                      dataKey='value'
                      position='top'
                      formatter={(value: number) => `${value.toFixed(0)}`}
                      style={{ fill: '#6d6d6d' }}
                    />
                    {chartData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={colorsForCharts[index + 10 ? index + 10 : index]}
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default OverallReferrersBarChart;
