import { useEffect, useState } from 'react';
import '../../../../styles/components/charts/chart.scss';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../store/store';
import get_graph_data_api_call from '../../../../lib/api/stats/get_graph_data_api_call';
import { useSelector } from 'react-redux';
import ChartWithConfig from '../../ChartWithConfig';
import isNotEmptyString from '../../../../lib/helper-functions/isNotEmptyString';
import capitalizeFirstLetterOfEachWord from '../../../../lib/helper-functions/capitalizeFirstLetterOfEachWord';
import ScreenSizeBasedCRBarChart from './ScreenSizeBasedCRBarChart';

type Props = {
  timeFilter: string;
  customPeriodFilterDateRange: {
    start_date: string | undefined;
    end_date: string | undefined;
  };
  eventFilter: string;
};

const MainScreenSizeBasedCRChart = ({
  timeFilter = 'all',
  customPeriodFilterDateRange,
  eventFilter = 'purchase',
}: Props) => {
  const { t } = useTranslation();
  const stats = useSelector((state: RootState) => state.user?.stats);
  const [params, setParams] = useState<string>(
    `?graph_type=conversion_rate&time_range=${timeFilter}&event_type=${eventFilter}&group_by=screen_size`
  );

  const [response, setResponse] = useState<any>([]);

  //Comments and titles
  const [title, setTitle] = useState<string>(
    t('ScreenSizeBasedConversionRate')
  );
  const [comment, setComment] = useState<string>(
    'TheConversionRateOfCartsToCheckoutsBasedOnScreenSize'
  );

  const renderCharts = () => {
    return <ScreenSizeBasedCRBarChart apiResponse={response} />;
  };

  /* ---------API CALL----------- */
  const getGraphData = async () => {
    try {
      // Call to get conversion rate stats by location
      const res = await get_graph_data_api_call(
        stats.ai_tracker_api_key,
        stats.ai_tracker_access_token,
        params
      );

      if (res) {
        setResponse(res);
      } else {
      }
    } catch (error) {
      console.error('Error fetching conversion rate data:', error);
    }
  };

  useEffect(() => {
    const title1 =
      eventFilter === 'purchase'
        ? t('ScreenSizeBasedConversionRate')
        : `${t('ScreenSizeBased')} ${eventFilter} ${t('ClickThroughRate')}`;
    setTitle(capitalizeFirstLetterOfEachWord(title1));

    const title2 =
      eventFilter === 'purchase'
        ? t('TheConversionRateOfCartsToCheckoutsBasedOnScreenSize')
        : `${t('ScreenSizeBased')} ${t('ClickThroughRate')} ${t(
            'of'
          )} ${eventFilter}`;

    setComment(capitalizeFirstLetterOfEachWord(title2));
  }, [eventFilter]);

  useEffect(() => {
    let dateRangeURLParam = '';
    if (timeFilter === 'custom') {
      const { start_date, end_date } = customPeriodFilterDateRange || {};
      if (start_date === undefined || end_date === undefined) {
        return;
      }
      dateRangeURLParam = `&start_date=${start_date}&end_date=${end_date}`;
    }

    let eventURLParam = '';
    if (isNotEmptyString(eventFilter)) {
      eventURLParam = `&event_type=${eventFilter}`;
    }

    setParams(
      `?graph_type=conversion_rate&time_range=${timeFilter}${dateRangeURLParam}&group_by=device${eventURLParam}`
    );
  }, [timeFilter, customPeriodFilterDateRange, eventFilter]);

  useEffect(() => {
    if (
      stats.ai_tracker_api_key !== '' &&
      stats.ai_tracker_access_token !== ''
    ) {
      setResponse({ type: 'load', data: [] });
      getGraphData();
    }
  }, [params, stats.ai_tracker_access_token, stats.ai_tracker_api_key]);

  return (
    <ChartWithConfig title={title} comment={comment}>
      {renderCharts()}
    </ChartWithConfig>
  );
};

export default MainScreenSizeBasedCRChart;
