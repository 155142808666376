import { useState, useEffect, CSSProperties } from 'react';
import {
  initialInputData,
  transformInputData,
} from '../../../../lib/constants/trackingClassesData';
import MainSquareButton from '../../../../ui/buttons/MainSquareButton';
import { useTranslation } from 'react-i18next';
import post_integration_api_call from '../../../../lib/api/integration/post_integration_api_call';

import isNotEmptyString from '../../../../lib/helper-functions/isNotEmptyString';
import store from '../../../../store/store';
import { setMessage } from '../../../../store/reducers/appSlice';
import getInitialValuesViaTrackingConfigurations from '../../../../lib/helper-functions/getInitialValuesViaTrackingConfigurations';
import ToggleSwitch from '../../../../ui/buttons/ToggleSwitch';
import AINormalInput from '../../../../ui/inputs/AINormalInput';
import AISelectInput from '../../../../ui/inputs/AISelectInput';
import CustomSVGs from '../../../../ui/customSVGs/CustomSVGs';
import { arrowHeadRightSVG } from '../../../../lib/allCustomSVGs';
import '../../../../styles/generic/collapseList.scss';
import { AnimatePresence, motion } from 'framer-motion';

interface Props {
  data: any;
  isAutofillEnabled: boolean;
  isTrackerON: boolean;
  setScriptKey: (key: string) => void;
}

const CMSEvents = ({
  data,
  isAutofillEnabled,
  isTrackerON,
  setScriptKey,
}: Props) => {
  const [activeItem, setActiveItem] = useState('');
  const [isFormErrorWarningOpen, setIsFormErrorWarningOpen] = useState(false);
  const [isEveryInputNull, setIsEveryInputNull] = useState(true);
  const [trackingInputValues, setTrackingInputValues] = useState<any>(
    data?.length > 0
      ? getInitialValuesViaTrackingConfigurations(
          data[data?.length - 1]?.configuration,
          transformInputData(initialInputData)
        )
      : transformInputData(initialInputData)
  );
  const [
    isDefaultPaymentStatusTrackingEnabled,
    setIsDefaultPaymentStatusTrackingEnabled,
  ] = useState<boolean>(
    data?.length > 0
      ? data[data?.length - 1]?.configuration
          ?.is_default_payment_status_tracking_enabled === 'true'
        ? true
        : false
      : false
  );

  const [isDuplicateValue, setIsDuplicateValue] = useState(false);
  const [isEmptyValue, setIsEmptyValue] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [paymentTrackingMethodComplete, setPaymentTrackingMethodComplete] =
    useState(
      isNotEmptyString(
        trackingInputValues['statusCompletePaymentTrackingMechanism']
      )
        ? trackingInputValues['statusCompletePaymentTrackingMechanism']
        : 'null'
    );

  const [paymentTrackingMethodFailed, setPaymentTrackingMethodFailed] =
    useState(
      isNotEmptyString(
        trackingInputValues['statusFailedPaymentTrackingMechanism']
      )
        ? trackingInputValues['statusFailedPaymentTrackingMechanism']
        : 'null'
    );

  const [paymentTrackingMethodExpired, setPaymentTrackingMethodExpired] =
    useState(
      isNotEmptyString(
        trackingInputValues['statusExpiredPaymentTrackingMechanism']
      )
        ? trackingInputValues['statusExpiredPaymentTrackingMechanism']
        : 'null'
    );

  const [paymentTrackingMethodCancel, setPaymentTrackingMethodCancel] =
    useState(
      isNotEmptyString(
        trackingInputValues['statusCancelPaymentTrackingMechanism']
      )
        ? trackingInputValues['statusCancelPaymentTrackingMechanism']
        : 'null'
    );

  const [
    paymentTrackingMethodCompleteRequired,
    setPaymentTrackingMethodCompleteRequired,
  ] = useState<boolean>(false);

  const { t } = useTranslation();

  //? Currently only WooCommerce and LightSpeed are supported (default CSS configs prefill & default payment tracking)
  const cmsType =
    data?.length > 0
      ? data[data?.length - 1]?.additional_configs?.CMS_type === 'woocommerce'
        ? 'WooCommerce'
        : data[data?.length - 1]?.additional_configs?.CMS_type === 'lightspeed'
        ? 'LightSpeed'
        : ''
      : '';

  useEffect(() => {
    //? If the CMS type is not supported, disable the default payment status tracking
    if (!isNotEmptyString(cmsType)) {
      setIsDefaultPaymentStatusTrackingEnabled(false);
    }
  }, []);

  const toggleWarningPopupModal = () => {
    setIsFormErrorWarningOpen(!isFormErrorWarningOpen);
  };

  const onInputChange = (e: any, value: string) => {
    setTrackingInputValues((prev: any) => ({
      ...prev,
      [value]: e.target.value,
    }));
  };

  useEffect(() => {
    if (!isFormErrorWarningOpen) {
      setIsEmptyValue(false);
    }
  }, [isFormErrorWarningOpen]);

  async function onConfirmHandler() {
    setIsSubmitting(true);
    const autofillEnabledObj: { is_autofill_enabled: 'true' | 'false' } = {
      is_autofill_enabled: isAutofillEnabled ? 'true' : 'false',
    };
    const trackerSwitchObj: { is_tracker_active: 'true' | 'false' } = {
      is_tracker_active: isTrackerON ? 'true' : 'false',
    };
    try {
      const sendingData: any = {
        name: 'checkout-tracking-class-names',
        type: 'tracker',
        configuration: isEveryInputNull
          ? { key: 'value' }
          : {
              ...trackingInputValues,
              is_default_payment_status_tracking_enabled:
                isDefaultPaymentStatusTrackingEnabled ? 'true' : 'false',
              statusCompletePaymentTrackingMechanism:
                paymentTrackingMethodComplete !== 'null' &&
                paymentTrackingMethodComplete !== null
                  ? paymentTrackingMethodComplete
                  : '',
              statusFailedPaymentTrackingMechanism:
                paymentTrackingMethodFailed !== 'null' &&
                paymentTrackingMethodFailed !== null
                  ? paymentTrackingMethodFailed
                  : '',
              statusExpiredPaymentTrackingMechanism:
                paymentTrackingMethodExpired !== 'null' &&
                paymentTrackingMethodExpired !== null
                  ? paymentTrackingMethodExpired
                  : '',
              statusCancelPaymentTrackingMechanism:
                paymentTrackingMethodCancel !== 'null' &&
                paymentTrackingMethodCancel !== null
                  ? paymentTrackingMethodCancel
                  : '',
            },
      };

      if (data?.length > 0) {
        sendingData['_method'] = 'put';
      }

      const customMsg = t('CheckoutTrackingDataSuccess-msg');

      const res = await post_integration_api_call(
        sendingData,
        data?.length > 0 ? data[data?.length - 1]?.slug : undefined,
        autofillEnabledObj,
        trackerSwitchObj,
        customMsg
      );
      if (res?.api_key) {
        setIsSubmitting(false);
        setScriptKey(res?.api_key);
      } else {
        setIsSubmitting(false);
      }
    } catch (exception) {
      setIsSubmitting(false);
      console.log('Error during authentication request');
      // submitLogToBugsnag('error', `${exception}`);
    }
  }

  function inputValidating(inputValuesObj: any) {
    if (
      (!paymentTrackingMethodComplete ||
        paymentTrackingMethodComplete === 'null' ||
        paymentTrackingMethodComplete === null) &&
      !isDefaultPaymentStatusTrackingEnabled
    ) {
      setPaymentTrackingMethodCompleteRequired(true);
      store.dispatch(
        setMessage({
          message: t('TrackingMechanismStatus') + ' ' + t('IsRequired'),
          messageType: 'error',
        })
      );
      return;
    }

    const valueSet = new Set();
    let isEmpty = false;
    let isDuplicate = false;

    const isEverythingNull = !Object.values(inputValuesObj).some(
      (value) => value !== ''
    );
    setIsEveryInputNull(isEverythingNull);

    for (const key in inputValuesObj) {
      const currentValue = inputValuesObj[key];

      if (valueSet.has(currentValue)) {
        isDuplicate = true;
        break;
      } else if (currentValue === '') {
        isEmpty = true;
      } else {
        valueSet.add(currentValue);
      }
    }

    if (isDuplicate) {
      setIsDuplicateValue(true);
      toggleWarningPopupModal();
    } else if (isEmpty) {
      setIsEmptyValue(true);
      toggleWarningPopupModal();
    } else {
      onConfirmHandler();
    }
  }

  function onSubmitHandler() {
    inputValidating(trackingInputValues);
  }

  const mainTitleStyle: CSSProperties = {
    marginTop: '0',
    fontSize: '2.3rem',
    color: 'var(--main-text-color)',
    fontFamily: 'var(--main-font-primary)',
    fontWeight: 600,
  };

  return (
    <div className='normalForm--wrapper'>
      <form
        style={{ paddingTop: '0' }}
        id='Edit-tracking-class-input-form'
        className='normalForm'
      >
        <div style={{ marginTop: '0' }} className='normalForm__center'>
          {/* ------------------------------------------------------- */}
          <h2 style={mainTitleStyle}>{t('PaymentStatusTracking')}</h2>
          {isNotEmptyString(cmsType) && (
            <div
              className='shopPage__container1-alignLeft'
              style={{
                marginBottom: isDefaultPaymentStatusTrackingEnabled
                  ? '4rem'
                  : '0rem',
              }}
            >
              <ToggleSwitch
                isOn={isDefaultPaymentStatusTrackingEnabled}
                title={t('UseDefaultPaymentStatusTracking') + ` ${cmsType}`}
                onClick={() => {
                  setIsDefaultPaymentStatusTrackingEnabled(
                    !isDefaultPaymentStatusTrackingEnabled
                  );
                }}
                classes='margin-top-2rem'
                id='notification-settings-is-custom-message'
              />
            </div>
          )}
          {/* ------------------------------------------------------- */}

          {!isDefaultPaymentStatusTrackingEnabled && (
            <div className='collapseList'>
              {/* -------------------Complete Start--------------------- */}
              <div className='collapseList__item'>
                <div
                  onClick={() => {
                    if (activeItem === 'complete') {
                      setActiveItem('');
                    } else {
                      setActiveItem('complete');
                    }
                  }}
                  className='collapseList__item--top'
                >
                  <div className='collapseList__item--top-left'>
                    <div className='collapseList__item--top-left-tag collapseList__item--top-left-tag-green'>
                      {t('Complete')}
                    </div>
                    <div className='collapseList__item--top-left-title'>
                      {t('PaymentStatusTrackingConfiguration')}
                    </div>
                  </div>
                  <div
                    className={`collapseList__item--top-right ${
                      activeItem === 'complete'
                        ? 'collapseList__item--top-right-open'
                        : ''
                    }`}
                  >
                    <CustomSVGs svg={arrowHeadRightSVG} />
                  </div>
                </div>
                <div className='collapseList__item--bottom'>
                  <AnimatePresence>
                    {activeItem === 'complete' && (
                      <motion.div
                        className='collapseList__item--bottom-content'
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <div className='normalForm__container1'>
                          <div className='normalForm__container1--left'>
                            <AISelectInput
                              defaultValue={paymentTrackingMethodComplete}
                              setSelect={(input: string) => {
                                setPaymentTrackingMethodComplete(input);
                                setPaymentTrackingMethodCompleteRequired(false);
                              }}
                              required={paymentTrackingMethodCompleteRequired}
                              selectOptionArr={[
                                {
                                  OptionValue: 'null',
                                  OptionName: t('SelectOption'),
                                  OptionDisabled: true,
                                },
                                {
                                  OptionValue: 'css',
                                  OptionName: t(
                                    'PaymentStatusTracking-Option-1'
                                  ),
                                },
                                {
                                  OptionValue: 'urlParam',
                                  OptionName: t(
                                    'PaymentStatusTracking-Option-2'
                                  ),
                                },
                                {
                                  OptionValue: 'redirectURL',
                                  OptionName: t(
                                    'PaymentStatusTracking-Option-3'
                                  ),
                                },
                              ]}
                              placeholder={t('TrackingMechanismStatus')}
                              id={'statusCompletePaymentTrackingMechanism'}
                            />
                          </div>
                        </div>
                        <div className='normalForm__container1'>
                          {paymentTrackingMethodComplete === 'css' && (
                            <>
                              <div className='normalForm__container1--left'>
                                <AINormalInput
                                  changeListeners={[
                                    (e) =>
                                      onInputChange(
                                        e,
                                        'statusCompletePaymentCSSElement'
                                      ),
                                  ]}
                                  id={`statusCompletePaymentCSSElement`}
                                  value={
                                    trackingInputValues
                                      ? trackingInputValues[
                                          'statusCompletePaymentCSSElement'
                                        ]
                                      : ''
                                  }
                                  placeholder={t('PaymentCSSElement')}
                                  innerPlaceholder={t('CssClassesPlaceholder')}
                                  required={true}
                                  title={t('CssClassInput-validation')}
                                  pattern='^[.#][^\s\[\]\{\}\(\)!@#$%^&*+=,:<>;:]+$'
                                />
                              </div>
                              <div className='normalForm__container1--right'>
                                <AINormalInput
                                  changeListeners={[
                                    (e) =>
                                      onInputChange(
                                        e,
                                        'statusCompletePaymentPreDefinedText'
                                      ),
                                  ]}
                                  id={`statusCompletePaymentPreDefinedText`}
                                  value={
                                    trackingInputValues
                                      ? trackingInputValues[
                                          'statusCompletePaymentPreDefinedText'
                                        ]
                                      : ''
                                  }
                                  placeholder={t('PaymentPreDefinedText')}
                                  required={true}
                                />
                              </div>
                            </>
                          )}
                          {paymentTrackingMethodComplete === 'urlParam' && (
                            <>
                              <div className='normalForm__container1--left'>
                                <AINormalInput
                                  changeListeners={[
                                    (e) =>
                                      onInputChange(
                                        e,
                                        'statusCompletePaymentURLparam'
                                      ),
                                  ]}
                                  id={`statusCompletePaymentURLparam`}
                                  value={
                                    trackingInputValues
                                      ? trackingInputValues?.statusCompletePaymentURLparam
                                      : ''
                                  }
                                  placeholder={t('PaymentURLparam')}
                                  innerPlaceholder={t('URLparamPlaceholder')}
                                  required={true}
                                  title={t('URLparam-validation')}
                                  pattern='^[\w]+[\w\?&]*=[\w]+$'
                                />
                              </div>
                            </>
                          )}
                          {paymentTrackingMethodComplete === 'redirectURL' && (
                            <>
                              <div className='normalForm__container1--left'>
                                <AINormalInput
                                  changeListeners={[
                                    (e) =>
                                      onInputChange(
                                        e,
                                        'statusCompletePaymentUniqueURL'
                                      ),
                                  ]}
                                  id={`statusCompletePaymentUniqueURL`}
                                  value={
                                    trackingInputValues
                                      ? trackingInputValues[
                                          'statusCompletePaymentUniqueURL'
                                        ]
                                      : ''
                                  }
                                  placeholder={t('PaymentUniqueURL')}
                                  // innerPlaceholder='https://www.example.com'
                                  required={true}
                                  // title={t('Expected-URL-Format-validation')}
                                  // pattern='^(https?:\/\/|www\.)\S+\.\S+$'
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
              {/* -------------------Complete End--------------------- */}

              {/* -------------------Failed Start--------------------- */}
              <div className='collapseList__item'>
                <div
                  onClick={() => {
                    if (activeItem === 'failed') {
                      setActiveItem('');
                    } else {
                      setActiveItem('failed');
                    }
                  }}
                  className='collapseList__item--top'
                >
                  <div className='collapseList__item--top-left'>
                    <div className='collapseList__item--top-left-tag collapseList__item--top-left-tag-red'>
                      {t('Failed')}
                    </div>
                    <div className='collapseList__item--top-left-title'>
                      {t('PaymentStatusTrackingConfiguration')}
                    </div>
                  </div>
                  <div
                    className={`collapseList__item--top-right ${
                      activeItem === 'failed'
                        ? 'collapseList__item--top-right-open'
                        : ''
                    }`}
                  >
                    <CustomSVGs svg={arrowHeadRightSVG} />
                  </div>
                </div>
                <div className='collapseList__item--bottom'>
                  <AnimatePresence>
                    {activeItem === 'failed' && (
                      <motion.div
                        className='collapseList__item--bottom-content'
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <div className='normalForm__container1'>
                          <div className='normalForm__container1--left'>
                            <AISelectInput
                              defaultValue={paymentTrackingMethodFailed}
                              setSelect={setPaymentTrackingMethodFailed}
                              selectOptionArr={[
                                {
                                  OptionValue: 'null',
                                  OptionName: t('SelectOption'),
                                },
                                {
                                  OptionValue: 'css',
                                  OptionName: t(
                                    'PaymentStatusTracking-Option-1'
                                  ),
                                },
                                {
                                  OptionValue: 'urlParam',
                                  OptionName: t(
                                    'PaymentStatusTracking-Option-2'
                                  ),
                                },
                                {
                                  OptionValue: 'redirectURL',
                                  OptionName: t(
                                    'PaymentStatusTracking-Option-3'
                                  ),
                                },
                              ]}
                              placeholder={t('TrackingMechanismStatus')}
                              id={'statusFailedPaymentTrackingMechanism'}
                            />
                          </div>
                        </div>
                        <div className='normalForm__container1'>
                          {paymentTrackingMethodFailed === 'css' && (
                            <>
                              <div className='normalForm__container1--left'>
                                <AINormalInput
                                  changeListeners={[
                                    (e) =>
                                      onInputChange(
                                        e,
                                        'statusFailedPaymentCSSElement'
                                      ),
                                  ]}
                                  id={`statusFailedPaymentCSSElement`}
                                  value={
                                    trackingInputValues
                                      ? trackingInputValues[
                                          'statusFailedPaymentCSSElement'
                                        ]
                                      : ''
                                  }
                                  placeholder={t('PaymentCSSElement')}
                                  innerPlaceholder={t('CssClassesPlaceholder')}
                                  required={true}
                                  title={t('CssClassInput-validation')}
                                  pattern='^[.#][^\s\[\]\{\}\(\)!@#$%^&*+=,:<>;:]+$'
                                />
                              </div>
                              <div className='normalForm__container1--right'>
                                <AINormalInput
                                  changeListeners={[
                                    (e) =>
                                      onInputChange(
                                        e,
                                        'statusFailedPaymentPreDefinedText'
                                      ),
                                  ]}
                                  id={`statusFailedPaymentPreDefinedText`}
                                  value={
                                    trackingInputValues
                                      ? trackingInputValues[
                                          'statusFailedPaymentPreDefinedText'
                                        ]
                                      : ''
                                  }
                                  placeholder={t('PaymentPreDefinedText')}
                                  required={true}
                                />
                              </div>
                            </>
                          )}
                          {paymentTrackingMethodFailed === 'urlParam' && (
                            <>
                              <div className='normalForm__container1--left'>
                                <AINormalInput
                                  changeListeners={[
                                    (e) =>
                                      onInputChange(
                                        e,
                                        'statusFailedPaymentURLparam'
                                      ),
                                  ]}
                                  id={`statusFailedPaymentURLparam`}
                                  value={
                                    trackingInputValues
                                      ? trackingInputValues[
                                          'statusFailedPaymentURLparam'
                                        ]
                                      : ''
                                  }
                                  placeholder={t('PaymentURLparam')}
                                  innerPlaceholder={t('URLparamPlaceholder')}
                                  required={true}
                                  title={t('URLparam-validation')}
                                  pattern='^[\w]+[\w\?&]*=[\w]+$'
                                />
                              </div>
                            </>
                          )}
                          {paymentTrackingMethodFailed === 'redirectURL' && (
                            <>
                              <div className='normalForm__container1--left'>
                                <AINormalInput
                                  changeListeners={[
                                    (e) =>
                                      onInputChange(
                                        e,
                                        'statusFailedPaymentUniqueURL'
                                      ),
                                  ]}
                                  id={`statusFailedPaymentUniqueURL`}
                                  value={
                                    trackingInputValues
                                      ? trackingInputValues[
                                          'statusFailedPaymentUniqueURL'
                                        ]
                                      : ''
                                  }
                                  placeholder={t('PaymentUniqueURL')}
                                  // innerPlaceholder='https://www.example.com'
                                  required={true}
                                  // title={t('Expected-URL-Format-validation')}
                                  // pattern='^(https?:\/\/|www\.)\S+\.\S+$'
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
              {/* -------------------Failed End--------------------- */}

              {/* -------------------Expired Start--------------------- */}
              <div className='collapseList__item'>
                <div
                  onClick={() => {
                    if (activeItem === 'expired') {
                      setActiveItem('');
                    } else {
                      setActiveItem('expired');
                    }
                  }}
                  className='collapseList__item--top'
                >
                  <div className='collapseList__item--top-left'>
                    <div className='collapseList__item--top-left-tag collapseList__item--top-left-tag-yellow'>
                      {t('Expired')}
                    </div>
                    <div className='collapseList__item--top-left-title'>
                      {t('PaymentStatusTrackingConfiguration')}
                    </div>
                  </div>
                  <div
                    className={`collapseList__item--top-right ${
                      activeItem === 'expired'
                        ? 'collapseList__item--top-right-open'
                        : ''
                    }`}
                  >
                    <CustomSVGs svg={arrowHeadRightSVG} />
                  </div>
                </div>
                <div className='collapseList__item--bottom'>
                  <AnimatePresence>
                    {activeItem === 'expired' && (
                      <motion.div
                        className='collapseList__item--bottom-content'
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <div className='normalForm__container1'>
                          <div className='normalForm__container1--left'>
                            <AISelectInput
                              defaultValue={paymentTrackingMethodExpired}
                              setSelect={setPaymentTrackingMethodExpired}
                              selectOptionArr={[
                                {
                                  OptionValue: 'null',
                                  OptionName: t('SelectOption'),
                                },
                                {
                                  OptionValue: 'css',
                                  OptionName: t(
                                    'PaymentStatusTracking-Option-1'
                                  ),
                                },
                                {
                                  OptionValue: 'urlParam',
                                  OptionName: t(
                                    'PaymentStatusTracking-Option-2'
                                  ),
                                },
                                {
                                  OptionValue: 'redirectURL',
                                  OptionName: t(
                                    'PaymentStatusTracking-Option-3'
                                  ),
                                },
                              ]}
                              placeholder={t('TrackingMechanismStatus')}
                              id={'statusExpiredPaymentTrackingMechanism'}
                            />
                          </div>
                        </div>
                        <div className='normalForm__container1'>
                          {paymentTrackingMethodExpired === 'css' && (
                            <>
                              <div className='normalForm__container1--left'>
                                <AINormalInput
                                  changeListeners={[
                                    (e) =>
                                      onInputChange(
                                        e,
                                        'statusExpiredPaymentCSSElement'
                                      ),
                                  ]}
                                  id={`statusExpiredPaymentCSSElement`}
                                  value={
                                    trackingInputValues
                                      ? trackingInputValues[
                                          'statusExpiredPaymentCSSElement'
                                        ]
                                      : ''
                                  }
                                  placeholder={t('PaymentCSSElement')}
                                  innerPlaceholder={t('CssClassesPlaceholder')}
                                  required={true}
                                  title={t('CssClassInput-validation')}
                                  pattern='^[.#][^\s\[\]\{\}\(\)!@#$%^&*+=,:<>;:]+$'
                                />
                              </div>
                              <div className='normalForm__container1--right'>
                                <AINormalInput
                                  changeListeners={[
                                    (e) =>
                                      onInputChange(
                                        e,
                                        'statusExpiredPaymentPreDefinedText'
                                      ),
                                  ]}
                                  id={`statusExpiredPaymentPreDefinedText`}
                                  value={
                                    trackingInputValues
                                      ? trackingInputValues[
                                          'statusExpiredPaymentPreDefinedText'
                                        ]
                                      : ''
                                  }
                                  placeholder={t('PaymentPreDefinedText')}
                                  required={true}
                                />
                              </div>
                            </>
                          )}
                          {paymentTrackingMethodExpired === 'urlParam' && (
                            <>
                              <div className='normalForm__container1--left'>
                                <AINormalInput
                                  changeListeners={[
                                    (e) =>
                                      onInputChange(
                                        e,
                                        'statusExpiredPaymentURLparam'
                                      ),
                                  ]}
                                  id={`statusExpiredPaymentURLparam`}
                                  value={
                                    trackingInputValues
                                      ? trackingInputValues[
                                          'statusExpiredPaymentURLparam'
                                        ]
                                      : ''
                                  }
                                  placeholder={t('PaymentURLparam')}
                                  innerPlaceholder={t('URLparamPlaceholder')}
                                  required={true}
                                  title={t('URLparam-validation')}
                                  pattern='^[\w]+[\w\?&]*=[\w]+$'
                                />
                              </div>
                            </>
                          )}
                          {paymentTrackingMethodExpired === 'redirectURL' && (
                            <>
                              <div className='normalForm__container1--left'>
                                <AINormalInput
                                  changeListeners={[
                                    (e) =>
                                      onInputChange(
                                        e,
                                        'statusExpiredPaymentUniqueURL'
                                      ),
                                  ]}
                                  id={`statusExpiredPaymentUniqueURL`}
                                  value={
                                    trackingInputValues
                                      ? trackingInputValues[
                                          'statusExpiredPaymentUniqueURL'
                                        ]
                                      : ''
                                  }
                                  placeholder={t('PaymentUniqueURL')}
                                  // innerPlaceholder='https://www.example.com'
                                  required={true}
                                  // title={t('Expected-URL-Format-validation')}
                                  // pattern='^(https?:\/\/|www\.)\S+\.\S+$'
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
              {/* -------------------Expired End--------------------- */}

              {/* -------------------Cancel Start--------------------- */}
              <div className='collapseList__item'>
                <div
                  onClick={() => {
                    if (activeItem === 'cancel') {
                      setActiveItem('');
                    } else {
                      setActiveItem('cancel');
                    }
                  }}
                  className='collapseList__item--top'
                >
                  <div className='collapseList__item--top-left'>
                    <div className='collapseList__item--top-left-tag collapseList__item--top-left-tag-blue'>
                      {t('Cancel')}
                    </div>
                    <div className='collapseList__item--top-left-title'>
                      {t('PaymentStatusTrackingConfiguration')}
                    </div>
                  </div>
                  <div
                    className={`collapseList__item--top-right ${
                      activeItem === 'cancel'
                        ? 'collapseList__item--top-right-open'
                        : ''
                    }`}
                  >
                    <CustomSVGs svg={arrowHeadRightSVG} />
                  </div>
                </div>
                <div className='collapseList__item--bottom'>
                  <AnimatePresence>
                    {activeItem === 'cancel' && (
                      <motion.div
                        className='collapseList__item--bottom-content'
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <div className='normalForm__container1'>
                          <div className='normalForm__container1--left'>
                            <AISelectInput
                              defaultValue={paymentTrackingMethodCancel}
                              setSelect={setPaymentTrackingMethodCancel}
                              selectOptionArr={[
                                {
                                  OptionValue: 'null',
                                  OptionName: t('SelectOption'),
                                },
                                {
                                  OptionValue: 'css',
                                  OptionName: t(
                                    'PaymentStatusTracking-Option-1'
                                  ),
                                },
                                {
                                  OptionValue: 'urlParam',
                                  OptionName: t(
                                    'PaymentStatusTracking-Option-2'
                                  ),
                                },
                                {
                                  OptionValue: 'redirectURL',
                                  OptionName: t(
                                    'PaymentStatusTracking-Option-3'
                                  ),
                                },
                              ]}
                              placeholder={t('TrackingMechanismStatus')}
                              id={'statusCancelPaymentTrackingMechanism'}
                            />
                          </div>
                        </div>
                        <div className='normalForm__container1'>
                          {paymentTrackingMethodCancel === 'css' && (
                            <>
                              <div className='normalForm__container1--left'>
                                <AINormalInput
                                  changeListeners={[
                                    (e) =>
                                      onInputChange(
                                        e,
                                        'statusCancelPaymentCSSElement'
                                      ),
                                  ]}
                                  id={`statusCancelPaymentCSSElement`}
                                  value={
                                    trackingInputValues
                                      ? trackingInputValues[
                                          'statusCancelPaymentCSSElement'
                                        ]
                                      : ''
                                  }
                                  placeholder={t('PaymentCSSElement')}
                                  innerPlaceholder={t('CssClassesPlaceholder')}
                                  required={true}
                                  title={t('CssClassInput-validation')}
                                  pattern='^[.#][^\s\[\]\{\}\(\)!@#$%^&*+=,:<>;:]+$'
                                />
                              </div>
                              <div className='normalForm__container1--right'>
                                <AINormalInput
                                  changeListeners={[
                                    (e) =>
                                      onInputChange(
                                        e,
                                        'statusCancelPaymentPreDefinedText'
                                      ),
                                  ]}
                                  id={`statusCancelPaymentPreDefinedText`}
                                  value={
                                    trackingInputValues
                                      ? trackingInputValues[
                                          'statusCancelPaymentPreDefinedText'
                                        ]
                                      : ''
                                  }
                                  placeholder={t('PaymentPreDefinedText')}
                                  required={true}
                                />
                              </div>
                            </>
                          )}
                          {paymentTrackingMethodCancel === 'urlParam' && (
                            <>
                              <div className='normalForm__container1--left'>
                                <AINormalInput
                                  changeListeners={[
                                    (e) =>
                                      onInputChange(
                                        e,
                                        'statusCancelPaymentURLparam'
                                      ),
                                  ]}
                                  id={`statusCancelPaymentURLparam`}
                                  value={
                                    trackingInputValues
                                      ? trackingInputValues[
                                          'statusCancelPaymentURLparam'
                                        ]
                                      : ''
                                  }
                                  placeholder={t('PaymentURLparam')}
                                  innerPlaceholder={t('URLparamPlaceholder')}
                                  required={true}
                                  title={t('URLparam-validation')}
                                  pattern='^[\w]+[\w\?&]*=[\w]+$'
                                />
                              </div>
                            </>
                          )}
                          {paymentTrackingMethodCancel === 'redirectURL' && (
                            <>
                              <div className='normalForm__container1--left'>
                                <AINormalInput
                                  changeListeners={[
                                    (e) =>
                                      onInputChange(
                                        e,
                                        'statusCancelPaymentUniqueURL'
                                      ),
                                  ]}
                                  id={`statusCancelPaymentUniqueURL`}
                                  value={
                                    trackingInputValues
                                      ? trackingInputValues[
                                          'statusCancelPaymentUniqueURL'
                                        ]
                                      : ''
                                  }
                                  placeholder={t('PaymentUniqueURL')}
                                  // innerPlaceholder='https://www.example.com'
                                  required={true}
                                  // title={t('Expected-URL-Format-validation')}
                                  // pattern='^(https?:\/\/|www\.)\S+\.\S+$'
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
              {/* -------------------Cancel End--------------------- */}
            </div>
          )}
        </div>

        <div className='subPageListDelete__buttons'>
          <MainSquareButton
            onClick={(e) => {
              e.preventDefault();
              setTrackingInputValues(transformInputData(initialInputData));
              setIsDefaultPaymentStatusTrackingEnabled(false);
              setPaymentTrackingMethodComplete('null');
              setPaymentTrackingMethodFailed('null');
              setPaymentTrackingMethodExpired('null');
              setPaymentTrackingMethodCancel('null');
              setPaymentTrackingMethodCompleteRequired(false);
            }}
            type={'button'}
            value={t('Reset')}
            buttonType={'danger'}
          />
          <MainSquareButton
            type={'button'}
            value={t('Save')}
            loading={isSubmitting}
            onClick={onSubmitHandler}
            form='Edit-tracking-class-input-form'
          />
        </div>
      </form>
    </div>
  );
};

export default CMSEvents;
