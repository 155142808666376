import { useTranslation } from 'react-i18next';
import '../../../../styles/components/charts/chart.scss';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import ChartLegend from '../../ChartLegend';
import { useEffect, useState } from 'react';
import colorsForCharts from '../../../../lib/constants/colors';
import LoadingSpinner from '../../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../../ui/noData/NoData';

type Props = {
  setSelectedCountry: (country: string) => void;
  apiResponse: any;
};

const LocationBasedConversionRatePieChart = ({
  setSelectedCountry,
  apiResponse,
}: Props) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState<any[]>([]);
  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('pending');

  const getConversionRateData = async (res: any) => {
    setLoading('pending');
    if (res?.type === 'location' && res?.data) {
      if (!res?.data || !Array.isArray(res?.data || res?.data?.length === 0)) {
        setLoading('null');
        return;
      } else {
        const newChartData = res?.data?.map((item: any, i: number) => {
          return {
            label: item?.location,
            value: item?.conversion_rate || 0,
            color: colorsForCharts[i],
          };
        });

        setChartData(newChartData);
        setLoading('success');
      }
    }
  };

  useEffect(() => {
    if (apiResponse) {
      getConversionRateData(apiResponse);
    }
  }, [apiResponse]);

  const valueFormatter = (item: { value: number }) => `${item.value}%`;

  const data = {
    data: chartData,
    valueFormatter,
  };

  const onItemClickHandler = (e: any, d: any) => {
    if (d?.dataIndex !== undefined) {
      setSelectedCountry(data?.data[d.dataIndex]?.label);
    }
  };

  // const AdditionalStatsData = [
  //   {
  //     label: t('CompanyOverallCarts'),
  //     value: additionalStats?.companyOverallCarts,
  //     fillColor: '#8884d8',
  //     svg: addedCartSVG,
  //   },
  //   {
  //     label: t('CompanyOverallCheckouts'),
  //     value: additionalStats?.companyOverallCheckouts,
  //     fillColor: '#82ca9d',
  //     svg: completedCartSVG,
  //   },
  // ];

  return (
    <>
      {loading === 'pending' && <LoadingSpinner />}

      {loading === 'null' && (
        <NoData
          title={t('No data available')}
          subTitle={t('NeedMoreInteractionsToGenerateStats')}
        />
      )}
      {loading === 'success' && (
        <>
          <div className='chart__bottom--content-chart'>
            <div
              style={{ height: '25rem', width: '25rem' }}
              className='chart__bottom--content-chart-pie'
            >
              <PieChart
                margin={{ top: 5, bottom: 5, left: 5, right: 5 }}
                series={[
                  {
                    arcLabel: (item) => `${item.value}%`,
                    innerRadius: 50,
                    arcLabelMinAngle: 35,
                    arcLabelRadius: '70%',
                    highlightScope: { fade: 'global', highlight: 'item' },
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: 'gray',
                    },
                    ...data,
                  },
                ]}
                onItemClick={onItemClickHandler}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fontWeight: 'bold',
                  },
                }}
                slotProps={{
                  legend: {
                    hidden: true, // Set to true to hide the legend
                  },
                }}
              />
            </div>
            <div className='chart__bottom--content-chart-legend'>
              <div className='chart__bottom--content-chart-legend-content'>
                {chartData?.map((item, index) => (
                  <ChartLegend
                    key={index}
                    color={item?.color}
                    label={item?.label}
                    value={item?.value}
                    onClick={() => setSelectedCountry(item?.label)}
                    symbol='%'
                  />
                ))}
              </div>
            </div>
          </div>
          {/* <ChartAdditionalStats stats={AdditionalStatsData} /> */}
        </>
      )}
    </>
  );
};
export default LocationBasedConversionRatePieChart;
