import { useState, useEffect, CSSProperties } from 'react';
import {
  initialCatalogAndCartInputData,
  initialCheckoutInputData,
  initialGeneralInputData,
  initialInputData,
  initialProductInputData,
  prefillInputDataWithDefaultConfigsForCMS,
  transformInputData,
} from '../../../../lib/constants/trackingClassesData';
import MainSquareButton from '../../../../ui/buttons/MainSquareButton';
import { useTranslation } from 'react-i18next';
import post_integration_api_call from '../../../../lib/api/integration/post_integration_api_call';
import Popup from '../../../../ui/popup/Popup';
import CssClassesWarningMsg from './CssClassesWarningMsg';
import isNotEmptyString from '../../../../lib/helper-functions/isNotEmptyString';
import { RootState } from '../../../../store/store';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { decodeConfigurationsResponse } from '../../../../lib/helper-functions/decodeConfigurationsResponse';
import { useSelector } from 'react-redux';
import {
  downloadSVG,
  importSVG,
  writeSVG,
} from '../../../../lib/allCustomSVGs';
import HeaderStoreButton from '../../../../ui/buttons/headerStoreButton';
import CustomSVGs from '../../../../ui/customSVGs/CustomSVGs';
import ImageInput from '../../../../ui/inputs/ImageInput';
import getInitialValuesViaTrackingConfigurations from '../../../../lib/helper-functions/getInitialValuesViaTrackingConfigurations';
import AITagInput from '../../../../ui/inputs/AITagInput';

interface Props {
  data: any;
  isAutofillEnabled: boolean;
  isTrackerON: boolean;
  setScriptKey: (key: string) => void;
}

function BasicCssTracking({
  data,
  isAutofillEnabled,
  isTrackerON,
  setScriptKey,
}: Props) {
  const userCompany = useSelector((state: RootState) => state.user.company);
  const [selectedFile, setSelectedFile] = useState();
  const [previewFile, setPreviewFile] = useState<string>();
  const [isErrorFile, setIsErrorFile] = useState(false);
  const [isFileUploadOpen, setIsFileUploadOpen] = useState<boolean>(false);
  const [isFormErrorWarningOpen, setIsFormErrorWarningOpen] = useState(false);
  const [isEveryInputNull, setIsEveryInputNull] = useState(true);
  const [resetTagIncrement, setResetTagIncrement] = useState(0);
  const [trackingInputValues, setTrackingInputValues] = useState<any>(
    data?.length > 0
      ? getInitialValuesViaTrackingConfigurations(
          data[data?.length - 1]?.configuration,
          transformInputData(initialInputData)
        )
      : transformInputData(initialInputData)
  );
  const [isDuplicateValue, setIsDuplicateValue] = useState(false);
  const [isEmptyValue, setIsEmptyValue] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t } = useTranslation();

  //? Currently only WooCommerce and LightSpeed are supported (default CSS configs prefill & default payment tracking)
  const cmsType =
    data?.length > 0
      ? data[data?.length - 1]?.additional_configs?.CMS_type === 'woocommerce'
        ? 'WooCommerce'
        : data[data?.length - 1]?.additional_configs?.CMS_type === 'lightspeed'
        ? 'LightSpeed'
        : ''
      : '';

  const toggleWarningPopupModal = () => {
    setIsFormErrorWarningOpen(!isFormErrorWarningOpen);
  };

  const selectedTags = (tags: string[], id: string) => {
    setTrackingInputValues((prev: any) => ({
      ...prev,
      [id]: JSON.stringify(tags),
    }));
  };

  const getInitialTags = (value: any) => {
    if (typeof value === 'object' && Array.isArray(value)) {
      return value;
    }
    try {
      const parsedValue = JSON.parse(value);
      return typeof parsedValue === 'object' && Array.isArray(parsedValue)
        ? parsedValue
        : [value];
    } catch (error) {
      return [value];
    }
  };

  useEffect(() => {
    if (!isFormErrorWarningOpen) {
      setIsDuplicateValue(false);
      setIsEmptyValue(false);
    }
  }, [isFormErrorWarningOpen]);

  async function onConfirmHandler() {
    setIsSubmitting(true);
    const autofillEnabledObj: { is_autofill_enabled: 'true' | 'false' } = {
      is_autofill_enabled: isAutofillEnabled ? 'true' : 'false',
    };
    const trackerSwitchObj: { is_tracker_active: 'true' | 'false' } = {
      is_tracker_active: isTrackerON ? 'true' : 'false',
    };
    try {
      const sendingData: any = {
        name: 'checkout-tracking-class-names',
        type: 'tracker',
        configuration: isEveryInputNull
          ? { key: 'value' }
          : {
              ...trackingInputValues,
            },
      };

      if (data?.length > 0) {
        sendingData['_method'] = 'put';
      }

      const customMsg = t('CheckoutTrackingDataSuccess-msg');

      const res = await post_integration_api_call(
        sendingData,
        data?.length > 0 ? data[data?.length - 1]?.slug : undefined,
        autofillEnabledObj,
        trackerSwitchObj,
        customMsg
      );
      if (res?.api_key) {
        setIsSubmitting(false);
        setScriptKey(res?.api_key);
      } else {
        setIsSubmitting(false);
      }
    } catch (exception) {
      setIsSubmitting(false);
      console.log('Error during authentication request');
      // submitLogToBugsnag('error', `${exception}`);
    }
  }

  function inputValidating(inputValuesObj: any) {
    const valueSet = new Set();
    let isEmpty = false;
    let isDuplicate = false;

    const isEverythingNull = !Object.values(inputValuesObj).some(
      (value) => value !== ''
    );
    setIsEveryInputNull(isEverythingNull);

    for (const key in inputValuesObj) {
      const currentValue = inputValuesObj[key];

      if (valueSet.has(currentValue)) {
        isDuplicate = true;
        break;
      } else if (currentValue === '') {
        isEmpty = true;
      } else {
        valueSet.add(currentValue);
      }
    }

    if (isDuplicate) {
      setIsDuplicateValue(true);
      toggleWarningPopupModal();
    } else if (isEmpty) {
      setIsEmptyValue(true);
      toggleWarningPopupModal();
    } else {
      onConfirmHandler();
    }
  }

  function onSubmitHandler() {
    inputValidating(trackingInputValues);
  }

  const mainTitleStyle: CSSProperties = {
    marginTop: '0',
    fontSize: '2.3rem',
    color: 'var(--main-text-color)',
    fontFamily: 'var(--main-font-primary)',
    fontWeight: 600,
  };
  const sectionTitleStyle: CSSProperties = {
    marginTop: '2rem',
    fontSize: '1.75rem',
    color: 'var(--main-text-color)',
    fontFamily: 'var(--main-font-primary)',
    fontWeight: 600,
  };

  //*-------------------------- Import/Export CSS Configs - Start ----------------------------- *//
  const getLabelByKey = (key: string): string | undefined => {
    const item = initialInputData.find((el) => el.name === key);
    return item ? item.label : '';
  };

  const exportToExcel = (data: any[], sheetName: string, fileName: string) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    worksheet['!cols'] = [{ wch: 50 }, { wch: 50 }, { wch: 100 }];
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  };

  useEffect(() => {
    if (selectedFile) {
      handleImport(selectedFile);
    }
  }, [selectedFile]);

  const sampleTemplateExport = () => {
    const data = Object.entries(trackingInputValues)
      .filter(([key]) => !key.startsWith('status', 0))
      .map(([key]) => ({
        key,
        label: getLabelByKey(key),
        value: JSON.stringify([
          '.sample-class-1',
          '#sample-id-2',
          '#sample-id-4 .sample-class-3',
        ]),
      }));

    exportToExcel(
      data,
      'AdUp-Sample-Template',
      'AdUp-Sample-Tracking-Configurations-Template.xlsx'
    );
  };

  const handleExport = () => {
    const data = Object.entries(trackingInputValues)
      .filter(([key]) => !key.startsWith('status', 0))
      .map(([key, value]) => ({
        key,
        label: getLabelByKey(key),
        value: value || '',
      }));

    exportToExcel(
      data,
      userCompany?.name ?? 'AdUp',
      `${userCompany?.name ?? 'AdUp'}-Tracking-Configurations-by-AdUp.xlsx`
    );
  };

  const handleImport = (file: any) => {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      const newInputValues: any = { ...trackingInputValues };
      jsonData.forEach((item: any) => {
        const elementsArray = decodeConfigurationsResponse(item.value);

        //? Filter out invalid CSS selectors
        const filteredElementsArray = elementsArray.filter(
          (htmlSelector: string) =>
            /^(?!\s*$)[.#]?[^\[\]\{\}\(\)!@$%^&*+=,:<>;:"'`]+$/.test(
              htmlSelector
            )
        );

        newInputValues[item.key] = JSON.stringify(filteredElementsArray);
      });

      setTrackingInputValues(newInputValues);
    };
    reader.readAsArrayBuffer(file);
  };
  //*-------------------------- Import/Export CSS Configs - End ----------------------------- *//

  return (
    <div className='normalForm--wrapper'>
      <form
        style={{ paddingTop: '0' }}
        id='Edit-tracking-class-input-form'
        className='normalForm'
      >
        <h2 style={mainTitleStyle}>{t('CssConfiguration')}</h2>

        {/* <CssClassesHelp /> */}

        {/* ======================================================== */}
        <div style={{ display: 'flex', gap: '2rem', flexWrap: 'wrap' }}>
          {isNotEmptyString(cmsType) && (
            <div style={{ paddingBottom: '10px' }}>
              <HeaderStoreButton
                value={t('PrefillDefaultConfigurations') + ` ${cmsType}`}
                icon={<CustomSVGs svg={writeSVG} />}
                type='button'
                onClick={() => {
                  setTrackingInputValues(
                    prefillInputDataWithDefaultConfigsForCMS(
                      cmsType.toLocaleLowerCase()
                    )
                  );
                }}
              />
            </div>
          )}

          <div style={{ paddingBottom: '10px' }}>
            {!isFileUploadOpen && (
              <HeaderStoreButton
                value={t('ImportCSSConfigurations')}
                icon={<CustomSVGs svg={importSVG} />}
                type='button'
                onClick={() => {
                  setIsFileUploadOpen(true);
                }}
              />
            )}
            {isFileUploadOpen && (
              <>
                <div style={{ maxWidth: '40rem' }}>
                  <ImageInput
                    isFileUpload={true}
                    mainText={t('ImportCSSConfigurations')}
                    subText={`${t('AcceptedFileTypes')} (.xlsx, .csv)`}
                    acceptedFileTypes={'.xlsx, .xls, .csv'}
                    id={'import-csv'}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    preview={previewFile}
                    setPreview={setPreviewFile}
                    fileError={isErrorFile}
                    setFileError={setIsErrorFile}
                    manyImages={false}
                    removeImage={() => {
                      setSelectedFile(undefined);
                    }}
                    onClose={() => {
                      setIsFileUploadOpen(false);
                    }}
                  />
                </div>
                <div
                  className='imageInput-outer'
                  style={{
                    maxWidth: '40rem',
                    maxHeight: '3.5rem',
                    marginTop: '-0.8rem',
                    cursor: 'pointer',
                  }}
                  onClick={sampleTemplateExport}
                >
                  <button
                    type='button'
                    className='headerStoreButtonWithoutBorder'
                  >
                    {<CustomSVGs svg={downloadSVG} />}
                    <p>{t('DownloadTemplate')}</p>
                  </button>
                </div>
              </>
            )}
          </div>

          <div>
            <HeaderStoreButton
              value={t('ExportCSSConfigurations')}
              icon={<CustomSVGs svg={downloadSVG} />}
              type='button'
              onClick={() => {
                handleExport();
              }}
            />
          </div>
        </div>
        {/* ======================================================== */}
        <div className='normalForm__center'>
          <div style={sectionTitleStyle}>{t('CSSSelectorGuidelines')}</div>
          <ul
            style={{
              marginTop: '1rem',
              marginLeft: '2rem',
              listStyleType: 'disc',
            }}
          >
            {/* <li>
              <strong>{t('CheckoutTrackingAddClassesInstructions2-1')}</strong>
              {t('CheckoutTrackingAddClassesInstructions2-2')}{' '}
            </li> */}
            <li>
              <strong>{t('CheckoutTrackingAddClassesInstructions6-1')}</strong>
              {t('CheckoutTrackingAddClassesInstructions6-2')}{' '}
            </li>
            <li>
              <strong>{t('CheckoutTrackingAddClassesInstructions3-1')}</strong>
              {t('CheckoutTrackingAddClassesInstructions3-2')}{' '}
            </li>
            <li>
              <strong>{t('CheckoutTrackingAddClassesInstructions4-1')}</strong>
              {t('CheckoutTrackingAddClassesInstructions4-2')}{' '}
            </li>
            <li>
              <strong>{t('CheckoutTrackingAddClassesInstructions5-1')}</strong>
              {t('CheckoutTrackingAddClassesInstructions5-2')}{' '}
            </li>
          </ul>

          {/* ======================================================== */}

          <div style={sectionTitleStyle}>{t('GeneralElements')}</div>
          <div className='normalForm__container3'>
            {initialGeneralInputData?.map((el: any, index: number) => {
              if (!el?.is_hidden) {
                return (
                  <div className='normalForm__container3--item' key={index}>
                    <div className='normalForm__container3--item-content'>
                      <AITagInput
                        selectedTags={selectedTags}
                        id={`${el?.name}`}
                        initialTags={
                          trackingInputValues && trackingInputValues[el?.name]
                            ? getInitialTags(trackingInputValues[el?.name])
                            : []
                        }
                        label={el?.label}
                        innerPlaceholder={t('MultipleCssClassesPlaceholder')}
                        required={el?.is_required ? true : false}
                        // autoFocus={index === 0 ? true : false}
                        disabled={el?.is_active ? false : true}
                        validationText={t('CssClassInput-validation')}
                        pattern={
                          /^(?!\s*$)[.#]?[^\[\]\{\}\(\)!@$%^&*+=,:<>;:"'`]+$/
                        }
                        resetTagIncrement={resetTagIncrement}
                        showInfoIcon={true}
                      />
                    </div>
                  </div>
                );
              }
            })}
          </div>

          {/* ======================================================== */}
          <div style={sectionTitleStyle}>{t('ProductElements')}</div>
          <div className='normalForm__container3'>
            {initialProductInputData?.map((el: any, index: number) => {
              if (!el?.is_hidden) {
                return (
                  <div className='normalForm__container3--item' key={index}>
                    <div className='normalForm__container3--item-content'>
                      <AITagInput
                        selectedTags={selectedTags}
                        id={`${el?.name}`}
                        initialTags={
                          trackingInputValues && trackingInputValues[el?.name]
                            ? getInitialTags(trackingInputValues[el?.name])
                            : []
                        }
                        label={el?.label}
                        innerPlaceholder={t('MultipleCssClassesPlaceholder')}
                        required={el?.is_required ? true : false}
                        // autoFocus={index === 0 ? true : false}
                        disabled={el?.is_active ? false : true}
                        validationText={t('CssClassInput-validation')}
                        pattern={
                          /^(?!\s*$)[.#]?[^\[\]\{\}\(\)!@$%^&*+=,:<>;:"'`]+$/
                        }
                        resetTagIncrement={resetTagIncrement}
                        showInfoIcon={true}
                      />
                    </div>
                  </div>
                );
              }
            })}
          </div>

          {/* ======================================================== */}
          <div style={sectionTitleStyle}>{t('CatalogAndCartElements')}</div>
          <div className='normalForm__container3'>
            {initialCatalogAndCartInputData?.map((el: any, index: number) => {
              if (!el?.is_hidden) {
                return (
                  <div className='normalForm__container3--item' key={index}>
                    <div className='normalForm__container3--item-content'>
                      <AITagInput
                        selectedTags={selectedTags}
                        id={`${el?.name}`}
                        initialTags={
                          trackingInputValues && trackingInputValues[el?.name]
                            ? getInitialTags(trackingInputValues[el?.name])
                            : []
                        }
                        label={el?.label}
                        innerPlaceholder={t('MultipleCssClassesPlaceholder')}
                        required={el?.is_required ? true : false}
                        // autoFocus={index === 0 ? true : false}
                        disabled={el?.is_active ? false : true}
                        validationText={t('CssClassInput-validation')}
                        pattern={
                          /^(?!\s*$)[.#]?[^\[\]\{\}\(\)!@$%^&*+=,:<>;:"'`]+$/
                        }
                        resetTagIncrement={resetTagIncrement}
                        showInfoIcon={true}
                      />
                    </div>
                  </div>
                );
              }
            })}
          </div>

          {/* ======================================================== */}
          <div style={sectionTitleStyle}>{t('CheckoutElements')}</div>
          <div className='normalForm__container3'>
            {initialCheckoutInputData?.map((el: any, index: number) => {
              if (!el?.is_hidden) {
                return (
                  <div className='normalForm__container3--item' key={index}>
                    <div className='normalForm__container3--item-content'>
                      <AITagInput
                        selectedTags={selectedTags}
                        id={`${el?.name}`}
                        initialTags={
                          trackingInputValues && trackingInputValues[el?.name]
                            ? getInitialTags(trackingInputValues[el?.name])
                            : []
                        }
                        label={el?.label}
                        innerPlaceholder={t('MultipleCssClassesPlaceholder')}
                        required={el?.is_required ? true : false}
                        // autoFocus={index === 0 ? true : false}
                        disabled={el?.is_active ? false : true}
                        validationText={t('CssClassInput-validation')}
                        pattern={
                          /^(?!\s*$)[.#]?[^\[\]\{\}\(\)!@$%^&*+=,:<>;:"'`]+$/
                        }
                        resetTagIncrement={resetTagIncrement}
                        showInfoIcon={true}
                      />
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>

        <Popup
          styles={{
            backgroundColor: '#fff',
          }}
          setTrigger={toggleWarningPopupModal}
          trigger={isFormErrorWarningOpen}
        >
          <CssClassesWarningMsg
            handler={onConfirmHandler}
            closeModal={toggleWarningPopupModal}
            mainTitle={
              isEmptyValue
                ? t('EmptyFieldsWarningTitle')
                : isDuplicateValue
                ? t('DuplicateFieldsWarningTitle')
                : ''
            }
            subTitle1={
              isEmptyValue
                ? t('EmptyFieldsWarningSubTitle1')
                : isDuplicateValue
                ? t('DuplicateFieldsWarningSubTitle1')
                : ''
            }
          />
          <div className='warning'></div>
        </Popup>

        <div className='subPageListDelete__buttons'>
          <MainSquareButton
            onClick={(e) => {
              e.preventDefault();
              setTrackingInputValues(transformInputData(initialInputData));
              setResetTagIncrement((prev) => prev + 1);
              setSelectedFile(undefined);
              setPreviewFile(undefined);
              setIsErrorFile(false);
              setIsFileUploadOpen(false);
            }}
            type={'button'}
            value={t('Reset')}
            buttonType={'danger'}
          />
          <MainSquareButton
            type={'button'}
            value={t('Save')}
            loading={isSubmitting}
            onClick={onSubmitHandler}
            form='Edit-tracking-class-input-form'
          />
        </div>
      </form>
    </div>
  );
}

export default BasicCssTracking;
